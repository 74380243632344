$color_1: #ffffff;
$color_2: gold;
$color_3: #000;
$color_4: red;
$color_5: green;
$color_6: blue;
$color_7: #fff;
$color_8: grey;
$font-family_1: â€˜Alvi lahori Nastaleeq â€˜, â€™Jameel Noori Nastaleeqâ€™,
  â€™Urdu Naskh Asiatypeâ€™, Arial, Tahoma;
$background-color_1: #00a3ee;
$background-color_2: #ccc;
$background-color_3: white;
$background-color_4: #f56523;
$background-color_5: #efefef;
$background-color_6: #ebebeb;
$background-color_7: yellow;
$background-color_8: #93da27;
$background-color_9: rgba(0, 0, 0, 0.5);
$background-color_10: rgba(225, 225, 225, 0.5);
$background-color_11: green;
$background-color_12: red;
$background-color_13: #ffffff;
$background-color_14: #000;
$background-color_15: #25c8ff;
$border-color_1: transparent transparent #00a3ee transparent;
$border-color_2: transparent transparent #ffffff transparent;
$border-color_3: transparent transparent #fff;
$border-color_4: transparent transparent transparent #ff5141;
$border-color_5: black;
$border-color_6: green;
$border-color_7: red;
$border-color_8: white;

// @charset "UTF-8";
/* TOOLTIP */
/* @media only screen and (max-width : 768px) { .tooltipTrigger .tooltiptext { width: 93%; margin-right: 0px; position: fixed; top: initial; left: 10px; right: 10px; margin: 0; bottom: 60px;} .tooltipTrigger .tooltiptext::after { display: none; } } */
/* TOOLTIP */
/* Switch Slider Element */
/* Switch Slider Element */
/* Custom File Upload */
/* Custom File Upload */
/* Progress Bar */
@keyframes progressBar {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
/* Progress Bar */
/* Custion ScrollBar CSS */
/* Tag */
/* Track */
/* Handle */
/* Handle on hover */
/* Custion ScrollBar CSS */
/* ACCORDIANCE */
/* ACCORDIANCE */
/*tour styles*/
.flex {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex: initial !important;
}
.flex1 {
  flex: 1;
}
.flex2 {
  flex: 2 !important;
}
.flex3 {
  flex: 3 !important;
}
.flex100 {
  flex: 100% !important;
}
.flexNoShrink {
  flex-shrink: 0 !important;
}
.flexDirectionRow {
  -moz-box-direction: row;
  -webkit-box-direction: row;
  -webkit-box-orient: horizontal;
  -webkit-flex-direction: row;
  -ms-flexbox-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}
.flexDirectionCol {
  -moz-box-direction: column;
  -webkit-box-direction: column;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flexbox-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
.flexWrap {
  -moz-box-wrap: wrap;
  -webkit-box-wrap: wrap;
  -ms-flexbox-wrap: wrap;
  flex-wrap: wrap;
}
.flexNoWrap {
  -moz-box-wrap: nowrap;
  -webkit-box-wrap: nowrap;
  -ms-flexbox-wrap: nowrap;
  flex-wrap: nowrap;
}
.flexWrapReverse {
  -moz-box-wrap: wrap-reverse;
  -webkit-box-wrap: wrap-reverse;
  -ms-flexbox-wrap: wrap-reverse;
  flex-wrap: wrap-reverse;
}
.flexJustifyBetween {
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  justify-content: space-between;
}
.flexJustifyAround {
  -webkit-justify-content: space-around;
  -ms-flex-pack: space-around;
  justify-content: space-around;
}
.flexJustifyStart {
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
}
.flexJustifyEnd {
  -webkit-justify-content: flex-end;
  -ms-flex-pack: flex-end;
  justify-content: flex-end;
}
.flexJustifyCenter {
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.flexAlignItemsCenter {
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.flexAlignItemsBaseline {
  -webkit-align-items: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
}
.flexAlignItemsStart {
  -webkit-align-items: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
}
.flexAlignItemsEnd {
  -webkit-align-items: flex-end;
  -ms-flex-align: flex-end;
  align-items: flex-end;
}
.flexAlignItemsStretch {
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}
.flexAlignContentCenter {
  -webkit-box-align-content: center;
  -webkit-align-content: center;
  -ms-flex-align-content: center;
  align-content: center;
}
.flexAlignContentAround {
  -webkit-box-align-content: space-around;
  -webkit-align-content: space-around;
  -ms-flex-align-content: space-around;
  align-content: space-around;
}
.flexAlignContentStart {
  -webkit-box-align-content: flex-start;
  -webkit-align-content: flex-start;
  -ms-flex-align-content: flex-start;
  align-content: flex-start;
}
.flexAlignContentEnd {
  -webkit-box-align-content: flex-end;
  -webkit-align-content: flex-end;
  -ms-flex-align-content: flex-end;
  align-content: flex-end;
}
.flexAlignContentBetween {
  -webkit-box-align-content: space-between;
  -webkit-align-content: space-between;
  -ms-flex-align-content: space-between;
  align-content: space-between;
}
.flexAlignContentStretch {
  -webkit-box-align-content: stretch;
  -webkit-align-content: stretch;
  -ms-flex-align-content: stretch;
  align-content: stretch;
}
.flexAlignSelfCenter {
  -webkit-box-align-self: center;
  -webkit-align-self: center;
  -ms-flex-align-self: center;
  align-self: center;
}
.flexAlignSelfAround {
  -webkit-box-align-self: space-around;
  -webkit-align-self: space-around;
  -ms-flex-align-self: space-around;
  align-self: space-around;
}
.flexAlignSelfStart {
  -webkit-box-align-self: flex-start;
  -webkit-align-self: flex-start;
  -ms-flex-align-self: flex-start;
  align-self: flex-start;
}
.flexAlignSelfEnd {
  -webkit-box-align-self: flex-end;
  -webkit-align-self: flex-end;
  -ms-flex-align-self: flex-end;
  align-self: flex-end;
}
.flexAlignSelfBetween {
  -webkit-box-align-self: space-between;
  -webkit-align-self: space-between;
  -ms-flex-align-self: space-between;
  align-self: space-between;
}
.flexAlignSelfStretch {
  -webkit-box-align-self: stretch;
  -webkit-align-self: stretch;
  -ms-flex-align-self: stretch;
  align-self: stretch;
}
.flexSafariFix {
  -webkit-flex: 1 0 auto;
}
.gap5px {
  gap: 5px;
}
.gap10px {
  gap: 10px;
}
.gap12px {
  gap: 12px;
}
.gap15px {
  gap: 15px;
}
.gap20px {
  gap: 20px;
}
.mobiVisible {
  display: none !important;
}
.bdrWhite3px {
  border: white;
}
.desktopVisible {
  display: block !important;
}
.tooltipTrigger {
  position: relative;
  display: inline-block;
  cursor: pointer;
  .tooltiptext {
    z-index: 100000;
    line-height: normal;
    visibility: hidden;
    background-color: $background-color_1;
    color: $color_1;
    border-radius: 6px;
    position: absolute;
    padding: 10px;
    width: 170px;
    top: 137%;
    right: 50%;
    margin-right: -155px;
    &::after {
      content: " ";
      position: absolute;
      bottom: 100%;
      left: 5%;
      margin-right: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: $border-color_1;
    }
  }
  .tooltiptext.left {
    top: 137%;
    left: 0%;
    right: auto;
  }
  .tooltiptext.bgWhite {
    &::after {
      content: " ";
      position: absolute;
      bottom: 100%;
      left: 5%;
      margin-right: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: $border-color_2;
    }
  }
  &:hover {
    .tooltiptext {
      visibility: visible;
    }
  }
}
.top2px {
  top: 2px;
}
.switchEle {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 20px;
  input {
    opacity: 0;
    width: 0;
    height: 0;
    &:checked {
      + {
        .switchEleSlider {
          background-color: $background-color_4;
          &:before {
            -webkit-transform: translateX(22px);
            -ms-transform: translateX(22px);
            transform: translateX(22px);
          }
        }
      }
    }
    &:focus {
      + {
        .switchEleSlider {
          box-shadow: 0 0 1px #f56523;
        }
      }
    }
  }
  .switchEleSlider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $background-color_2;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    &:before {
      position: absolute;
      content: "";
      height: 15px;
      width: 15px;
      left: 3px;
      bottom: 2px;
      background-color: $background-color_3;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }
  }
  .switchEleSlider.round {
    border-radius: 34px;
    &:before {
      border-radius: 50%;
    }
  }
}
.customInputFile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
  + {
    label {
      font-size: 17px;
      font-weight: 700;
      cursor: pointer;
      * {
        pointer-events: none;
      }
    }
  }
}
.customFileList {
  margin-top: 10px;
  padding-right: 10px;
  .item {
    background-color: $background-color_5;
    padding: 5px 10px;
    margin-bottom: 10px;
    border-radius: 7px;
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
    -ms-border-radius: 7px;
    -o-border-radius: 7px;
  }
}
.progressContainer {
  background-color: $background-color_6;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  height: 8px;
  overflow: hidden;
  width: 100%;
  box-shadow: inset 0 -1px 1px rgba(255, 255, 255, 0.3);
  position: relative;
  .progressBar {
    display: block;
    height: 8px;
    position: relative;
    overflow: hidden;
    .progressMeter {
      background-color: $background-color_8;
      box-shadow: inset 0 2px 9px rgba(255, 255, 255, 0.3),
        inset 0 -2px 6px rgba(0, 0, 0, 0.4);
      animation-fill-mode: both;
      animation: progressBar 2s ease-in-out;
      -webkit-animation: progressBar 2s ease-in-out;
      width: 100%;
      height: 100%;
    }
  }
}
.frostedGlassBlueLiteBg {
  background-color: #95ccf871;
  backdrop-filter: blur(10px);
  color: #000000;
}
.frostedGlassWhiteLiteBg {
  background-color: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(10px);
}
.frostedGlassWhiteDarkBg {
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(10px);
}
.frostedGlassBlackLiteBg {
  background-color: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(10px);
}
.frostedGlassBlackDarkBg {
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);
}
.frostedGlassWhiteLiteBackDrop {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  z-index: 12;
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
}
.frostedGlassBlackDarkBackDrop {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  z-index: 12;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);
}
.bgYellow {
  background-color: $background-color_7;
}
.icon-color {
  color: $color_2;
}
.round {
  background-size: 100%;
  border-radius: 50%;
  overflow: hidden;
  img {
    display: block;
    margin: 0 auto !important;
    min-width: 100%;
    min-height: 100%;
  }
}
.imgResponsive {
  display: block;
  height: auto;
  max-width: 100%;
}
.imgCircle {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  border: 1px solid rgba(80, 80, 80, 0.6);
}
.icns {
  display: inline-block;
  background-size: contain !important;
}
.section1366 {
  max-width: 1366px;
  width: 100%;
  margin: 0 auto;
  position: relative;
}
.section1280 {
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
  position: relative;
}
.section1200 {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  position: relative;
}
.section1100 {
  max-width: 1100px;
  width: 100%;
  margin: 0 auto;
  position: relative;
}
.section1024 {
  max-width: 1024px;
  width: 100%;
  margin: 0 auto;
  position: relative;
}
.section960 {
  max-width: 1024px;
  width: 100%;
  margin: 0 auto;
  position: relative;
}
.section860 {
  max-width: 860px;
  width: 100%;
  margin: 0 auto;
  position: relative;
}
.section800 {
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
  position: relative;
}
.section760 {
  max-width: 760px;
  width: 100%;
  margin: 0 auto;
  position: relative;
}
.section735 {
  max-width: 735px;
  width: 100%;
  margin: 0 auto;
  position: relative;
}
.section660 {
  max-width: 660px;
  width: 100%;
  margin: 0 auto;
  position: relative;
}
.section630 {
  max-width: 630px;
  width: 100%;
  margin: 0 auto;
  position: relative;
}
.section600 {
  max-width: 600px;
  width: 100%;
  margin: 0 auto;
  position: relative;
}
.section560 {
  max-width: 560px;
  width: 100%;
  margin: 0 auto;
  position: relative;
}
.section500 {
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
  position: relative;
}
.section480 {
  max-width: 480px;
  width: 100%;
  margin: 0 auto;
  position: relative;
}
.section420 {
  max-width: 420px;
  width: 100%;
  margin: 0 auto;
  position: relative;
}
.section400 {
  max-width: 400px;
  width: 100%;
  margin: 0 auto;
  position: relative;
}
.section380 {
  max-width: 380px;
  width: 100%;
  margin: 0 auto;
  position: relative;
}
.section360 {
  max-width: 360px;
  width: 100%;
  margin: 0 auto;
  position: relative;
}
.section320 {
  max-width: 320px;
  width: 100%;
  margin: 0 auto;
  position: relative;
}
.section300 {
  max-width: 300px;
  width: 100%;
  margin: 0 auto;
  position: relative;
}
.section250 {
  max-width: 250px;
  width: 100%;
  margin: 0 auto;
  position: relative;
}
.section100 {
  max-width: 100px;
  width: 100%;
  margin: 0 auto;
  position: relative;
}
.dAuto {
  width: auto !important;
  height: auto !important;
}
.d1 {
  width: 1px !important;
  height: 1px !important;
}
.d5 {
  width: 5px !important;
  height: 5px !important;
}
.d10 {
  width: 10px !important;
  height: 10px !important;
}
.d12 {
  width: 12px !important;
  height: 12px !important;
}
.d15 {
  width: 15px !important;
  height: 15px !important;
}
.d16 {
  width: 16px !important;
  height: 16px !important;
}
.d18 {
  width: 18px !important;
  height: 18px !important;
}
.d20 {
  width: 20px !important;
  height: 20px !important;
}
.d22 {
  width: 22px !important;
  height: 22px !important;
}
.d25 {
  width: 25px !important;
  height: 25px !important;
}
.d30 {
  width: 30px !important;
  height: 30px !important;
}
.d35 {
  width: 35px !important;
  height: 35px !important;
}
.d40 {
  width: 40px !important;
  height: 40px !important;
}
.d45 {
  width: 45px !important;
  height: 45px !important;
}
.d50 {
  width: 50px !important;
  height: 50px !important;
}
.d55 {
  width: 55px !important;
  height: 55px !important;
}
.d60 {
  width: 60px !important;
  height: 60px !important;
}
.d65 {
  width: 65px !important;
  height: 65px !important;
}
.d70 {
  width: 70px !important;
  height: 70px !important;
}
.d80 {
  width: 80px !important;
  height: 80px !important;
}
.d90 {
  width: 90px !important;
  height: 90px !important;
}
.d100 {
  width: 100px !important;
  height: 100px !important;
}
.d110 {
  width: 110px !important;
  height: 110px !important;
}
.d120 {
  width: 120px !important;
  height: 120px !important;
}
.d130 {
  width: 130px !important;
  height: 130px !important;
}
.d150 {
  width: 150px !important;
  height: 150px !important;
}
.d160 {
  width: 160px !important;
  height: 160px !important;
}
.d200 {
  width: 200px !important;
  height: 200px !important;
}
.d230 {
  width: 230px !important;
  height: 230px !important;
}
.d2845 {
  width: 28px !important;
  height: 45px !important;
}
.d4980 {
  width: 49px !important;
  height: 80px !important;
}
.d6040 {
  width: 60px !important;
  height: 40px !important;
}
.d4060 {
  width: 40px !important;
  height: 60px !important;
}
.d6050 {
  width: 60px !important;
  height: 50px !important;
}
.d2016 {
  width: 20px !important;
  height: 60px !important;
}
.d912 {
  width: 9px !important;
  height: 12px !important;
}
.d5650 {
  width: 56px !important;
  height: 50px !important;
}
.d813 {
  width: 8px !important;
  height: 13px !important;
}
.d20060 {
  width: 200px !important;
  height: 60px !important;
}
.minD100 {
  width: 100% !important;
  min-height: 100px !important;
}
.minD160 {
  width: 160% !important;
  min-height: 160px !important;
}
.heightInitial {
  height: initial !important;
}
.widthInitial {
  width: initial !important;
}
.width0 {
  width: 0;
  max-width: 0px;
}
.fxWidth100 {
  width: 100%;
}
.width100 {
  width: 100%;
  width: 100% !important;
}
.fxWidth90 {
  width: 90%;
}
.width90 {
  width: 90%;
}
.fxWidth95 {
  width: 95%;
}
.width95 {
  width: 95%;
}
.fxWidth85 {
  width: 85%;
}
.width85 {
  width: 85%;
}
.fxWidth80 {
  width: 80%;
}
.width80 {
  width: 80%;
}
.fxWidth75 {
  width: 75%;
}
.width75 {
  width: 75%;
}
.fxWidth70 {
  width: 70%;
}
.width70 {
  width: 70%;
}
.fxWidth66 {
  width: 66%;
}
.width66 {
  width: 66%;
}
.fxWidth65 {
  width: 65%;
}
.width65 {
  width: 65%;
}
.fxWidth67 {
  width: 67%;
}
.width67 {
  width: 67%;
}
.fxWidth62 {
  width: 62%;
}
.width62 {
  width: 62%;
}
.fxWidth60 {
  width: 60%;
}
.width60 {
  width: 60%;
}
.fxWidth50 {
  width: 50% !important;
}
.width50 {
  width: 50% !important;
}
.fxWidth55 {
  width: 55%;
}
.width55 {
  width: 55%;
}
.fxWidth40 {
  width: 40%;
}
.width40 {
  width: 40%;
}
.fxWidth45 {
  width: 45%;
}
.width45 {
  width: 45%;
}
.fxWidth38 {
  width: 38%;
}
.width38 {
  width: 38%;
}
.fxWidth35 {
  width: 35%;
}
.width35 {
  width: 35%;
}
.fxWidth34 {
  width: 34%;
}
.width34 {
  width: 34%;
}
.fxWidth33 {
  width: 33%;
}
.width33 {
  width: 33%;
}
.fxWidth32 {
  width: 32%;
}
.width32 {
  width: 32%;
}
.fxWidth30 {
  width: 30%;
}
.width30 {
  width: 30%;
}
.fxWidth25 {
  width: 25%;
}
.width25 {
  width: 25%;
}
.fxWidth20 {
  width: 20%;
}
.width20 {
  width: 20%;
}
.fxWidth15 {
  width: 15%;
}
.width15 {
  width: 15%;
}
.fxWidth10 {
  width: 10%;
}
.width10 {
  width: 10%;
}
.fxWidth5 {
  width: 5%;
}
.width5 {
  width: 5%;
}
.maxWidth70px {
  max-width: 70px;
  width: 100%;
}
.maxWidth100px {
  max-width: 100px;
  width: 100%;
}
.maxWidth107px {
  max-width: 107px;
  width: 100%;
}
.maxWidth120px {
  max-width: 120px;
  width: 100%;
}
.maxWidth150px {
  max-width: 150px;
  width: 100%;
}
.maxWidth200px {
  max-width: 200px;
  width: 100%;
}
.maxWidth225px {
  max-width: 225px;
  width: 100%;
}
.maxWidth230px {
  max-width: 230px;
  width: 100%;
}
.maxWidth240px {
  max-width: 240px;
  width: 100%;
}
.maxWidth245px {
  max-width: 245px;
  width: 100%;
}
.maxWidth250px {
  max-width: 250px;
  width: 100%;
}
.maxWidth255px {
  max-width: 255px;
  width: 100%;
}
.maxWidth260px {
  max-width: 260px;
  width: 100%;
}
.maxWidth280px {
  max-width: 280px;
  width: 100%;
}
.maxWidth300px {
  max-width: 300px;
  width: 100%;
}
.maxWidth310px {
  max-width: 310px;
  width: 100%;
}
.maxWidth320px {
  max-width: 320px;
  width: 100%;
}
.maxWidth380px {
  max-width: 380px;
  width: 100%;
}
.maxWidth340px {
  max-width: 340px;
  width: 100%;
}
.maxWidth350px {
  max-width: 350px;
  width: 100%;
}
.maxWidth365px {
  max-width: 365px;
  width: 100%;
}
.maxWidth400px {
  max-width: 400px;
  width: 100%;
}
.maxWidth450px {
  max-width: 450px;
  width: 100%;
}
.maxWidth787px {
  max-width: 787px;
  width: 100%;
}
.minWidth100 {
  min-width: 100%;
}
.minWidth107px {
  min-width: 107px;
}
.minWidth150px {
  min-width: 150px;
}
.minWidth200px {
  min-width: 200px;
}
.minWidth225px {
  min-width: 225px;
}
.minWidth245px {
  min-width: 245px;
}
.minWidth250px {
  min-width: 250px;
}
.minWidth255px {
  min-width: 255px;
}
.minWidth280px {
  min-width: 280px;
}
.minWidth310px {
  min-width: 310px;
}
.minWidth320px {
  min-width: 320px;
}
.minWidth380px {
  min-width: 380px;
}
.minWidth33p {
  min-width: 33%;
}
.width140px {
  width: 140px;
}
.height40px {
  height: 40px;
}
.height45px {
  height: 45px;
}
.height50px {
  height: 50px;
}
.height60px {
  height: 60px;
}
.height65px {
  height: 65px;
}
.height80px {
  height: 80px;
}
.height90px {
  height: 90px;
}
.height100px {
  height: 100px;
}
.height200px {
  height: 200px;
}
.height300px {
  height: 300px;
}
.height400px {
  height: 400px;
}
.height70vh {
  height: 70vh;
}
.height100vh {
  height: 100vh;
}
.height50p {
  height: 50%;
}
.height100p {
  height: 100%;
}
.height75p {
  height: 75%;
}
.height80pxOverride {
  height: 80px !important;
}
.minHeight50vh {
  min-height: 50vh;
}
.minHeight75vh {
  min-height: 75vh;
}
.minHeight100vh {
  min-height: 100vh;
}
.maxHeight74vx {
  min-height: 84vh;
}
.minHeight100p {
  min-height: 100%;
}
.minHeight80px {
  min-height: 80px;
}
.minHeight90px {
  min-height: 90px;
}
.minHeight100px {
  min-height: 100px;
}
.minHeight140px {
  min-height: 140px;
}
.minHeight165px {
  min-height: 165px;
}
.minHeight160px {
  min-height: 160px;
}
.minHeight107px {
  min-height: 107px;
}
.minHeight150px {
  min-height: 150px;
}
.minHeight180px {
  min-height: 180px;
}
.minHeight188px {
  min-height: 188px;
}
.minHeight200px {
  min-height: 200px;
}
.minHeight215px {
  min-height: 215px;
}
.minHeight217px {
  min-height: 217px;
}
.minHeight250px {
  min-height: 250px;
}
.minHeight255px {
  min-height: 255px;
}
.minHeight300px {
  min-height: 300px;
}
.minHeight400px {
  min-height: 440px;
}
.minHeight420px {
  min-height: 420px;
}
.maxHeight75vh {
  max-height: 75vh;
}
.maxHeight90vh {
  max-height: 90vh;
}
.maxHeight50vh {
  max-height: 50vh;
}
.maxHeight50p {
  max-height: 50%;
}
.maxHeight14px {
  max-height: 14px;
}
.maxHeight20px {
  max-height: 20px;
}
.maxHeight35px {
  max-height: 35px;
}
.maxHeight40px {
  max-height: 40px;
}
.maxHeight50px {
  max-height: 50px;
}
.maxHeight60px {
  max-height: 60px;
}
.maxHeight70px {
  max-height: 70px;
}
.maxHeight75px {
  max-height: 75px;
}
.maxHeight80px {
  max-height: 80px;
}
.maxHeight85px {
  max-height: 85px;
}
.maxHeight90px {
  max-height: 90px;
}
.maxHeight100px {
  max-height: 100px;
}
.maxHeight110px {
  max-height: 110px;
}
.maxHeight120px {
  max-height: 120px;
}
.maxHeight200px {
  max-height: 200px;
}
.maxHeight235px {
  max-height: 235px;
}
.maxHeight240px {
  max-height: 240px;
}
.maxHeight250px {
  max-height: 250px;
}
.maxHeight300px {
  max-height: 300px;
}
.maxHeight380px {
  max-height: 380px;
}
.maxHeight400px {
  max-height: 400px;
}
.maxHeight450px {
  max-height: 450px;
}
.color-black {
  color: $color_3 !important;
}
.color-red {
  color: $color_4;
}
.color-green {
  color: $color_5;
}
.color-blue {
  color: $color_6;
}
.color-white {
  color: $color_7 !important;
}
.color-grey {
  color: $color_8;
}
.responsiveBreakText {
  display: block;
}
.responsiveBreakTextRev {
  display: inline-block;
}
.valignTop {
  vertical-align: top !important;
}
.valignBottom {
  vertical-align: bottom !important;
}
.valignMiddle {
  vertical-align: middle !important;
}
.nonSelectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -webkit-tap-highlight-color: transparent;
}
.wrdBreakAll {
  word-break: break-all;
}
.wrdBreakWord {
  word-break: break-word;
}
.opacity5 {
  opacity: 0.5;
}
.opacity6 {
  opacity: 0.6;
}
.opacity7 {
  opacity: 0.7;
}
.opacity8 {
  opacity: 0.8;
}
.opacity9 {
  opacity: 0.9;
}
.rtl {
  direction: rtl;
  font-family: $font-family_1;
  line-height: 1.5em;
  text-align: justify;
}
.ltr {
  direction: ltr;
}
.txtNoWrap {
  white-space: nowrap !important;
}
.txtCenter {
  text-align: center !important;
}
.txtLeft {
  text-align: left !important;
}
.txtRight {
  text-align: right !important;
}
.txtJustify {
  text-align: justify !important;
}
.txtTransformCaps {
  text-transform: capitalize;
}
.txtTransformUp {
  text-transform: uppercase;
}
.txtTransformLow {
  text-transform: lowercase;
}
.txtTransformNone {
  text-transform: none !important;
}
.txtTruncate {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.lineClip1 {
  -webkit-line-clamp: 1 !important;
}
.lineClip2 {
  -webkit-line-clamp: 2 !important;
}
.lineClip3 {
  -webkit-line-clamp: 3 !important;
}
.txtShadow {
  text-shadow: 0 0 15px rgba(0, 0, 0, 0.55);
}
.txtAlignResponsive {
  text-align: left !important;
}
.txtRightAlignResponsive {
  text-align: right !important;
}
p.dropCap {
  &:first-child {
    &:first-letter {
      float: left;
      font-size: 75px;
      line-height: 60px;
      padding-top: 4px;
      padding-right: 8px;
      padding-left: 3px;
    }
  }
}
.letterSpacing0px {
  letter-spacing: 0;
}
.letterSpacing1px {
  letter-spacing: 1px;
}
.letterSpacingM1px {
  letter-spacing: -1px;
}
.letterSpacingM2px {
  letter-spacing: -2px;
}
.lineHeightNormal {
  line-height: normal !important;
}
.lineHeight48px {
  line-height: 48px !important;
}
.lineHeight36px {
  line-height: 36px !important;
}
.lineHeight28px {
  line-height: 28px !important;
}
.lineHeight26px {
  line-height: 26px !important;
}
.lineHeight25px {
  line-height: 25px !important;
}
.lineHeight24px {
  line-height: 24px !important;
}
.lineHeight23px {
  line-height: 23px !important;
}
.lineHeight22px {
  line-height: 22px !important;
}
.lineHeight21px {
  line-height: 21px !important;
}
.lineHeight20px {
  line-height: 20px !important;
}
.lineHeight19px {
  line-height: 19px !important;
}
.lineHeight18px {
  line-height: 18px !important;
}
.lineHeight17px {
  line-height: 17px !important;
}
.lineHeight16px {
  line-height: 16px !important;
}
.lineHeight15px {
  line-height: 15px !important;
}
.lineHeight12px {
  line-height: 12px !important;
}
.lineHeight10px {
  line-height: 10px !important;
}
.bold {
  font-weight: 700;
}
.fontWeightNormal {
  font-weight: 400;
}
.fontWeight300 {
  font-weight: 300 !important;
}
.fontWeight400 {
  font-weight: 400 !important;
}
.fontWeight500 {
  font-weight: 500 !important;
}
.fontWeight600 {
  font-weight: 600 !important;
}
.fontWeight700 {
  font-weight: 700 !important;
}
.fontWeight800 {
  font-weight: 800 !important;
}
.fontWeight900 {
  font-weight: 900 !important;
}
.font7 {
  font-size: 7px;
}
.font8 {
  font-size: 8px !important;
}
.font9 {
  font-size: 9px !important;
}
.font10 {
  font-size: 10px;
}
.font11 {
  font-size: 11px !important;
}
.font12 {
  font-size: 12px;
}
.font13 {
  font-size: 13px;
}
.font14 {
  font-size: 14px !important;
}
.font15 {
  font-size: 15px;
}
.font16 {
  font-size: 16px !important;
}
.font17 {
  font-size: 17px;
}
.font18 {
  font-size: 18px;
}
.font19 {
  font-size: 19px;
}
.font20 {
  font-size: 20px;
}
.font21 {
  font-size: 21px;
}
.font22 {
  font-size: 22px;
}
.font23 {
  font-size: 23px;
}
.font24 {
  font-size: 24px;
}
.font25 {
  font-size: 25px;
}
.font26 {
  font-size: 26px;
}
.font27 {
  font-size: 27px;
}
.font28 {
  font-size: 28px;
}
.font29 {
  font-size: 29px;
}
.font30 {
  font-size: 30px;
}
.font31 {
  font-size: 31px;
}
.font32 {
  font-size: 32px;
}
.font36 {
  font-size: 36px;
}
.font70P {
  font-size: 70% !important;
}
.rotate-180deg {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.rotate-90deg {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}
.flipHorizontal {
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  filter: FlipH;
  -ms-filter: FlipH;
}
.flipVertical {
  -moz-transform: scaleY(-1);
  -o-transform: scaleY(-1);
  -webkit-transform: scaleY(-1);
  transform: scaleY(-1);
  filter: FlipV;
  -ms-filter: FlipV;
}
.mapFrame {
  position: relative;
  height: 400px;
  iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    border: none;
  }
}
.right {
  float: right;
}
.left {
  float: left;
}
.overflowHidden {
  overflow: hidden !important;
}
.overflowInitial {
  overflow: initial !important;
}
.overflowAuto {
  overflow: auto !important;
}
.horizontalScrollContainer {
  overflow-y: hidden;
  overflow-x: auto;
}
.verticalScrollContainer {
  overflow-y: auto;
  overflow-x: hidden;
  &:hover {
    overflow-y: auto;
    overflow-x: hidden;
  }
}
.scrollBarStyle {
  &::-webkit-scrollbar {
    width: 0px;
    height: 6px;
    -webkit-transition: width 0.5s;
    -moz-transition: width 0.5s;
    -o-transition: width 0.5s;
    transition: width 0.5s;
  }
  &:hover {
    &::-webkit-scrollbar {
      width: 7px;
    }
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: #999;
    border-radius: 30px;
    &:hover {
      background: #555;
    }
  }
}
.tintBlack {
  background-color: $background-color_9;
}
.tintWhite {
  background-color: $background-color_10;
}
.bg-none {
  background: none;
}
.bg-green {
  background-color: $background-color_11;
}
.bg-red {
  background-color: $background-color_12;
}
.bgWhite {
  background-color: $background-color_13 !important;
}
.bg-black {
  background-color: $background-color_14 !important;
}
.color-FFFFFF {
  color: $color_1;
}
.bg-cccccc {
  background-color: $background-color_2;
}
.bg-25c8ff {
  background-color: $background-color_15;
}
.hide {
  display: none;
}
.show {
  display: block;
}
.displayFlex {
  display: inline-flex;
}
.displayInline {
  display: inline-block;
}
.displayblock {
  display: block;
}
.displayNone {
  display: none !important;
}
.listStyleNone {
  list-style: none;
}
.clear {
  clear: both;
}
.cursorPtr {
  cursor: pointer !important;
}
.cursorTxt {
  cursor: text !important;
}
.cursorGrabbing {
  cursor: grabbing !important;
}
.divider {
  border-bottom: 1px solid #dbdbdb;
  width: 100%;
  max-width: 500px;
  margin: 10px auto;
}
.showContainer {
  display: none;
}
.showContainer.active {
  display: block;
}
.arwTop {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 10px 12px;
  border-color: $border-color_3;
}
.arwRight {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 0 6px 10px;
  border-color: $border-color_4;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}
.bdrRadius0 {
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  border-radius: 0 !important;
}
.bdrRadius4px {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
.bdrRadius5px {
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}
.bdrRadius6px {
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
}
.bdrRadius7px {
  -webkit-border-radius: 7px !important;
  -moz-border-radius: 7px !important;
  border-radius: 7px !important;
}
.bdrRadius10px {
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  border-radius: 10px !important;
}
.bdrRadius12px {
  -webkit-border-radius: 12px !important;
  -moz-border-radius: 12px !important;
  border-radius: 12px !important;
}
.bdrRadius15px {
  -webkit-border-radius: 15px !important;
  -moz-border-radius: 15px !important;
  border-radius: 15px !important;
}
.bdrRadius19px {
  -webkit-border-radius: 19px !important;
  -moz-border-radius: 19px !important;
  border-radius: 19px !important;
}
.bdrRadius25px {
  -webkit-border-radius: 25px !important;
  -moz-border-radius: 25px !important;
  border-radius: 25px !important;
}
.bdrRadius50px {
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
}
.bdrRadius50p {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
.bdrRadiusTopRight50px {
  border-top-right-radius: 50px;
  -webkit--o-border-top-right-radius: 50px;
  -moz--o-border-top-right-radius: 50px;
  -ms--o-border-top-right-radius: 50px;
  -o--o-border-top-right-radius: 50px;
}
.bdrRadiusTopLeft50px {
  border-top-left-radius: 50px;
  -webkit--o-border-top-left-radius: 50px;
  -moz--o-border-top-left-radius: 50px;
  -ms--o-border-top-left-radius: 50px;
  -o--o-border-top-left-radius: 50px;
}
.bdrNone {
  border: none !important;
}
.bdr {
  border: 1px solid !important;
}
.bdrSolid {
  border-style: solid;
}
.bdrColorBlack {
  border-color: $border-color_5;
}
.bdrColorGreen {
  border-color: $border-color_6;
}
.bdrColorRed {
  border-color: $border-color_7;
}
.bdrColorWhite {
  border-color: $border-color_8;
}
.bdrWidth1px {
  border-width: 1px;
}
.bdrWidth2px {
  border-width: 2px;
}
.bdrWidth3px {
  border-width: 3px;
}
.bdrWidth10px {
  border-width: 10px;
}
.bdrTop {
  border-top: 1px solid;
}
.bdrRight {
  border-right: 1px solid;
}
.bdrBtm {
  border-bottom: 1px solid;
}
.bdrLeft {
  border-left: 1px solid;
}
.bdr-cccccc {
  border: 1px solid #cccccc;
}
.bdr-ffffff {
  border: 1px solid #ffffff;
}
.bdr-ebebeb {
  border: 1px solid green;
}
.bdrTop-ebebeb {
  border-top: 1px solid #ebebeb;
}
.bdrRight-ebebeb {
  border-right: 1px solid #ebebeb;
}
.bdrBtm-ebebeb {
  border-bottom: 1px solid #ebebeb;
}
.bdrLeft-ebebeb {
  border-left: 1px solid #ebebeb;
}
.bdr-dddddd {
  border: 1px solid #ddd;
}
.bdrTop-dddddd {
  border-top: 1px solid #ddd;
}
.bdrRight-dddddd {
  border-right: 1px solid #ddd;
}
.bdrBtm-dddddd {
  border-bottom: 1px solid #ddd;
}
.bdrLeft-dddddd {
  border-left: 1px solid #ddd;
}
.bdrLeft-ffffff {
  border-left: 1px solid #fff;
}
.boxShadowNone {
  box-shadow: none !important;
}
.boxShadow {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
}
.boxShadowDown {
  -webkit-box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.1);
}
.boxInnerShadowDown {
  -webkit-box-shadow: inset 0 -1px 4px 0 rgba(0, 0, 0, 0.75);
  -moz-box-shadow: inset 0 -1px 4px 0 rgba(0, 0, 0, 0.75);
  box-shadow: inset 0 -1px 4px 0 rgba(0, 0, 0, 0.75);
}
.boxShadowRight {
  -webkit-box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
    0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
    0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12);
}
.boxShadowLeft {
  -webkit-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2),
    -7px 0px 7px 0 rgba(0, 0, 0, 0.14), 0 -1px 4px 0 rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2),
    -7px 0px 7px 0 rgba(0, 0, 0, 0.14), 0 -1px 4px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2),
    -7px 0px 7px 0 rgba(0, 0, 0, 0.14), 0 -1px 4px 0 rgba(0, 0, 0, 0.12);
}
.boxShadowUp {
  -webkit-box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.43);
  -moz-box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.43);
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.43);
  position: relative;
  z-index: 100;
}
.posFixed {
  position: fixed !important;
}
.posRelative {
  position: relative;
}
.posAbsolute {
  position: absolute;
}
.posSticky {
  top: 0;
  position: -webkit-sticky;
  position: sticky;
}
.zIndexM1 {
  z-index: -1;
}
.zIndex0 {
  z-index: 0;
}
.zIndex1 {
  z-index: 1;
}
.zIndex2 {
  z-index: 2;
}
.zIndex10 {
  z-index: 10;
}
.zIndex20 {
  z-index: 20;
}
.zIndex900 {
  z-index: 900;
}
.zIndex100 {
  z-index: 100;
}
.zIndex1000 {
  z-index: 1000;
}
.zIndex1001 {
  z-index: 1001;
}
.zIndex1002 {
  z-index: 1002;
}
.zIndex1003 {
  z-index: 1003;
}
.posTop {
  top: 0;
}
.posRight {
  right: 0;
}
.posLeft {
  left: 0;
}
.posBtm {
  bottom: 0;
}
.posTop5px {
  top: 5px;
}
.posRight5px {
  right: 5px;
}
.posBtm5px {
  bottom: 5px;
}
.posLeft5px {
  left: 5px;
}
.posTopM5px {
  top: -5px;
}
.posRightM5px {
  right: -5px;
}
.posBtmM5px {
  bottom: -5px;
}
.posLeftM5px {
  left: -5px;
}
.posTopM10px {
  top: -10px;
}
.posRightM10px {
  right: -10px;
}
.posBtmM10px {
  bottom: -10px;
}
.posLeftM10px {
  left: -10px;
}
.posTopM14px {
  top: -14px;
}
.posRightM14px {
  right: -14px;
}
.posBtmM14px {
  bottom: -14px;
}
.posLeftM14px {
  left: -14px;
}
.posLeftM20px {
  left: -20px;
}
.posTop10px {
  top: 10px;
}
.posRight10px {
  right: 10px;
}
.posBtm10px {
  bottom: 10px;
}
.posLeft10px {
  left: 10px;
}
.posTop15px {
  top: 15px;
}
.posRight15px {
  right: 15px;
}
.posBtm15px {
  bottom: 15px;
}
.posLeft15px {
  left: 15px;
}
.posTop20px {
  top: 20px;
}
.posRight20px {
  right: 20px;
}
.posBtm20px {
  bottom: 20px;
}
.posLeft20px {
  left: 20px;
}
.posTop30px {
  top: 30px;
}
.posRight30px {
  right: 30px;
}
.posBtm30px {
  bottom: 30px;
}
.posLeft30px {
  left: 30px;
}
.posTop70px {
  top: 70px;
}
.posRight70px {
  right: 70px;
}
.posBtm70px {
  bottom: 70px;
}
.posLeft70px {
  left: 70px;
}
.posTop100px {
  top: 100px;
}
.posRight100px {
  right: 100px;
}
.posBtm100px {
  bottom: 100px;
}
.posLeft100px {
  left: 100px;
}
.posTop150px {
  top: 150px;
}
.posRight150px {
  right: 150px;
}
.posBtm150px {
  bottom: 150px;
}
.posLeft150px {
  left: 100px;
}
.posTopM3px {
  top: -3px;
}
.posRightM3px {
  right: -3px;
}
.posBtmM3px {
  bottom: -3px;
}
.posLeftM3px {
  left: -3px;
}
.posTopM25px {
  top: -25px;
}
.posRightM25px {
  right: -25px;
}
.posBtmM25px {
  bottom: -25px;
}
.posLeftM25px {
  left: -25px;
}
.posTopM30px {
  top: -30px;
}
.posRightM30px {
  right: -30px;
}
.posBtmM30px {
  bottom: -30px;
}
.posLeftM30px {
  left: -30px;
}
.posTop45p {
  top: 45%;
}
.posBtm45p {
  bottom: 45%;
}
.posRight45p {
  right: 45%;
}
.posLeft45p {
  left: 45%;
}
.posTopM24p {
  top: -24%;
}
.posRightM24p {
  right: -24%;
}
.posBtmM24p {
  bottom: -24%;
}
.posLeftM24p {
  left: -24%;
}
.posTopM30p {
  top: -30%;
}
.posRightM30p {
  right: -30%;
}
.posBtmM30p {
  bottom: -30%;
}
.posLeftM30p {
  left: -30%;
}
.posTopM45p {
  top: -45%;
}
.posBtmM45p {
  bottom: -45%;
}
.posRightM45p {
  right: -45%;
}
.posLeftM45p {
  left: -45%;
}
.posTopM80p {
  top: -80%;
}
.posBtmM80p {
  bottom: -80%;
}
.posRightM80p {
  right: -80%;
}
.posLeftM80p {
  left: -80%;
}
.posTopM100p {
  top: -100%;
}
.posBtmM100p {
  bottom: -100%;
}
.posRightM100p {
  right: -100%;
}
.posLeftM100p {
  left: -100%;
}
.posTop48p {
  top: 48%;
}
.posBtm48p {
  bottom: 48%;
}
.posRight48p {
  right: 48%;
}
.posLeft48p {
  left: 48%;
}
.pad0 {
  padding: 0;
}
.pad2 {
  padding: 2px;
}
.pad3 {
  padding: 3px;
}
.pad3535 {
  padding: 3px 5px;
}
.pad5 {
  padding: 5px;
}
.pad6 {
  padding: 6px;
}
.pad8 {
  padding: 8px;
}
.pad10 {
  padding: 10px;
}
.pad15 {
  padding: 15px;
}
.pad18 {
  padding: 18px;
}
.pad20 {
  padding: 20px;
}
.pad25 {
  padding: 25px;
}
.pad30 {
  padding: 30px;
}
.pad35 {
  padding: 35px;
}
.pad40 {
  padding: 40px;
}
.pad50 {
  padding: 50px;
}
.pad60 {
  padding: 60px;
}
.pad70 {
  padding: 70px;
}
.pad80 {
  padding: 80px;
}
.pad100 {
  padding: 100px;
}
.pad150 {
  padding: 150px;
}
.pad200 {
  padding: 200px;
}
.pad250 {
  padding: 250px;
}
.pad300 {
  padding: 300px;
}
.pad0005 {
  padding: 0 5px;
}
.pad1505 {
  padding: 15px 5px;
}
.pad1515 {
  padding: 1px 5px;
}
.pad2525 {
  padding: 2px 5px;
}
.pad3525 {
  padding: 3px 5px 2px;
}
.pad0008 {
  padding: 0 8px;
}
.pad0310 {
  padding: 3px 10px;
}
.pad0308 {
  padding: 3px 8px;
}
.pad0306 {
  padding: 3px 6px;
}
.pad0203 {
  padding: 2px 4px;
}
.pad0510 {
  padding: 5px 10px;
}
.pad0810 {
  padding: 8px 10px;
}
.pad0515 {
  padding: 5px 15px;
}
.pad1215 {
  padding: 12px 15px;
}
.pad0815 {
  padding: 8px 15px;
}
.pad1012 {
  padding: 10px 12px;
}
.pad1015 {
  padding: 10px 15px;
}
.pad1504 {
  padding: 15px 04px;
}
.pad1510 {
  padding: 15px 10px;
}
.pad1020 {
  padding: 10px 20px;
}
.pad1025 {
  padding: 10px 25px;
}
.pad1040 {
  padding: 10px 40px;
}
.pad1030 {
  padding: 10px 30px;
}
.pad1530 {
  padding: 15px 30px;
}
.pad2010 {
  padding: 20px 10px;
}
.pad3010 {
  padding: 30px 10px;
}
.padright40 {
  padding-right: 40px;
}
.padleft40 {
  padding-left: 40px;
}
.padtop40 {
  padding-top: 40px;
  padding-top: 40px;
}
.padbtm40 {
  padding-bottom: 40px;
  padding-bottom: 40px;
}
.padright45 {
  padding-right: 45px;
}
.padleft45 {
  padding-left: 45px;
}
.padtop45 {
  padding-top: 45px;
}
.padbtm45 {
  padding-bottom: 45px;
}
.padright50 {
  padding-right: 50px;
}
.padleft50 {
  padding-left: 50px;
}
.padtop50 {
  padding-top: 50px;
}
.padbtm50 {
  padding-bottom: 50px;
}
.padright60 {
  padding-right: 60px;
}
.padleft60 {
  padding-left: 60px;
}
.padtop60 {
  padding-top: 60px;
}
.padbtm60 {
  padding-bottom: 60px;
}
.padright70 {
  padding-right: 70px;
}
.padleft70 {
  padding-left: 70px;
}
.padtop70 {
  padding-top: 70px;
}
.padbtm70 {
  padding-bottom: 70px;
}
.padright80 {
  padding-right: 80px;
}
.padleft80 {
  padding-left: 80px;
}
.padtop80 {
  padding-top: 80px;
}
.padbtm80 {
  padding-bottom: 80px;
}
.padright90 {
  padding-right: 90px;
}
.padleft90 {
  padding-left: 90px;
}
.padtop90 {
  padding-top: 90px;
}
.padbtm90 {
  padding-bottom: 90px;
}
.padright100 {
  padding-right: 100px;
}
.padleft100 {
  padding-left: 100px;
}
.padtop100 {
  padding-top: 100px;
}
.padbtm100 {
  padding-bottom: 100px;
}
.padright110 {
  padding-right: 110px;
}
.padleft110 {
  padding-left: 110px;
}
.padtop110 {
  padding-top: 110px;
}
.padbtm110 {
  padding-bottom: 110px;
}
.padright120 {
  padding-right: 120px;
}
.padleft120 {
  padding-left: 120px;
}
.padtop120 {
  padding-top: 120px;
}
.padbtm120 {
  padding-bottom: 120px;
}
.padright130 {
  padding-right: 130px;
}
.padleft130 {
  padding-left: 130px;
}
.padtop130 {
  padding-top: 130px;
}
.padbtm130 {
  padding-bottom: 130px;
}
.padright140 {
  padding-right: 140px;
}
.padleft140 {
  padding-left: 140px;
}
.padtop140 {
  padding-top: 140px;
}
.padbtm140 {
  padding-bottom: 140px;
}
.padright2 {
  padding-right: 2px;
}
.padleft2 {
  padding-left: 2px;
}
.padtop2 {
  padding-top: 2px;
}
.padbtm2 {
  padding-bottom: 2px;
}
.padleft0 {
  padding-left: 0;
}
.padright0 {
  padding-right: 2px;
}
.padtop0 {
  padding-top: 0;
}
.padbtm0 {
  padding-bottom: 2px;
}
.padright3 {
  padding-right: 3px;
}
.padleft3 {
  padding-left: 3px;
}
.padtop3 {
  padding-top: 3px;
}
.padbtm3 {
  padding-bottom: 3px;
}
.padright5 {
  padding-right: 5px;
}
.padleft5 {
  padding-left: 5px;
}
.padtop5 {
  padding-top: 5px;
}
.padbtm5 {
  padding-bottom: 5px;
}
.padright10 {
  padding-right: 10px;
}
.padleft10 {
  padding-left: 10px;
}
.padtop10 {
  padding-top: 10px;
}
.padbtm10 {
  padding-bottom: 10px;
}
.padtop15 {
  padding-top: 15px;
}
.padbtm15 {
  padding-bottom: 15px;
}
.padright15 {
  padding-right: 15px;
}
.padleft15 {
  padding-left: 15px;
}
.padright20 {
  padding-right: 20px;
}
.padleft20 {
  padding-left: 20px;
}
.padtop20 {
  padding-top: 20px;
}
.padbtm20 {
  padding-bottom: 20px;
}
.padright30 {
  padding-right: 30px;
}
.padleft30 {
  padding-left: 30px;
}
.padtop30 {
  padding-top: 30px;
}
.padbtm30 {
  padding-bottom: 30px;
}
.padright35 {
  padding-right: 35px;
}
.padleft35 {
  padding-left: 35px;
}
.padtop35 {
  padding-top: 35px;
}
.padbtm35 {
  padding-bottom: 35px;
}
.padtop150 {
  padding-top: 150px;
}
.padright150 {
  padding-right: 150px;
}
.padbtm150 {
  padding-bottom: 150px;
}
.padleft150 {
  padding-left: 150px;
}
.mrgn0 {
  margin: 0 !important;
}
.mrgn1 {
  margin: 1px;
}
.mrgn3 {
  margin: 3px;
}
.mrgn5 {
  margin: 5px;
}
.mrgn7 {
  margin: 7px;
}
.mrgn8 {
  margin: 8px;
}
.mrgn9 {
  margin: 9px;
}
.mrgn10 {
  margin: 10px;
}
.mrgn15 {
  margin: 15px;
}
.mrgn20 {
  margin: 20px;
}
.mrgn25 {
  margin: 25px;
}
.mrgn30 {
  margin: 30px;
}
.mrgn35 {
  margin: 35px;
}
.mrgn40 {
  margin: 40px;
}
.mrgn50 {
  margin: 50px;
}
.mrgnauto {
  margin: 0 auto;
}
.mrgn3auto {
  margin: 3px auto;
}
.mrgn10auto {
  margin: 10px auto;
}
.mrgn15auto {
  margin: 15px auto;
}
.mrgn20auto {
  margin: 20px auto;
}
.mrgn215 {
  margin: 2px 15px;
}
.mrgn1005 {
  margin: 10px 5px;
}
.mrgn1012 {
  margin: 10px 12px;
}
.mrgn0515 {
  margin: 5px 15px;
  margin: 5px 15px;
}
.mrgn1015 {
  margin: 10px 15px;
}
.mrgn1020 {
  margin: 10px 20px;
}
.mrgn1510 {
  margin: 15px 10px;
}
.mrgn2010 {
  margin: 20px 10px;
}
.mrgn3010 {
  margin: 30px 10px;
}
.mrgn2015 {
  margin: 20px 15px;
}
.mrgntopM50p {
  margin-top: -50%;
  margin-top: -50%;
}
.mrgntopM30px {
  margin-top: -30px;
}
.mrgntopM50px {
  margin-top: -50px;
}
.mrgnrightM5 {
  margin-right: -5px;
}
.mrgnleftM5 {
  margin-left: -5px;
}
.mrgntopM5 {
  margin-top: -5px;
}
.mrgnbtmM5 {
  margin-bottom: -5px;
}
.mrgnright0 {
  margin-left: 0;
}
.mrgnleft0 {
  margin-right: 0;
}
.mrgntop0 {
  margin-top: 0;
}
.mrgnbtm0 {
  margin-bottom: 0;
}
.mrgnright1 {
  margin-right: 1px;
}
.mrgnleft1 {
  margin-left: 1px;
}
.mrgntop1 {
  margin-top: 1px;
}
.mrgnbtm1 {
  margin-bottom: 1px;
}
.mrgnright3 {
  margin-right: 3px;
}
.mrgnleft3 {
  margin-left: 3px;
}
.mrgntop3 {
  margin-top: 3px;
}
.mrgnbtm3 {
  margin-bottom: 3px;
}
.mrgnright5 {
  margin-right: 5px;
}
.mrgnleft5 {
  margin-left: 5px;
}
.mrgntop5 {
  margin-top: 5px;
}
.mrgnbtm5 {
  margin-bottom: 5px;
}
.mrgnright8 {
  margin-right: 8px;
}
.mrgnleft8 {
  margin-left: 8px;
}
.mrgntop8 {
  margin-top: 8px;
}
.mrgnbtm8 {
  margin-bottom: 8px;
}
.mrgnright10 {
  margin-right: 10px;
}
.mrgnleft10 {
  margin-left: 10px;
}
.mrgntop10 {
  margin-top: 10px;
}
.mrgnM10 {
  margin: -10px;
}
.mrgnbtm10 {
  margin-bottom: 10px;
}
.mrgnrightM10 {
  margin-right: -10px;
}
.mrgnleftM10 {
  margin-left: -10px;
}
.mrgntopM10 {
  margin-top: -10px;
}
.mrgnbtmM10 {
  margin-bottom: -10px;
}
.mrgnrightM12 {
  margin-right: -12px;
}
.mrgnleftM12 {
  margin-left: -12px;
}
.mrgntopM12 {
  margin-top: -12px;
}
.mrgnbtmM12 {
  margin-bottom: -12px;
}
.mrgnrightM15 {
  margin-right: -15px;
}
.mrgnleftM15 {
  margin-left: -15px;
}
.mrgntopM15 {
  margin-top: -15px;
}
.mrgnbtmM15 {
  margin-bottom: -15px;
}
.mrgnrightM20 {
  margin-right: -20px;
}
.mrgnleftM20 {
  margin-left: -20px;
}
.mrgntopM20 {
  margin-top: -20px;
}
.mrgnbtmM20 {
  margin-bottom: -20px;
}
.mrgnrightM30 {
  margin-right: -30px;
}
.mrgnleftM30 {
  margin-left: -30px;
}
.mrgntopM30 {
  margin-top: -30px;
}
.mrgnbtmM30 {
  margin-bottom: -30px;
}
.mrgnrightM50p {
  margin-right: -50%;
}
.mrgnleftM50p {
  margin-left: -50%;
}
.mrgnbtmM50p {
  margin-bottom: -50%;
}
.mrgnrightM100p {
  margin-right: -100%;
}
.mrgnleftM100p {
  margin-left: -100%;
}
.mrgntopM100p {
  margin-top: -100%;
}
.mrgnbtmM100p {
  margin-bottom: -100%;
}
.mrgnright15 {
  margin-right: 15px;
}
.mrgnleft15 {
  margin-left: 15px;
}
.mrgntop15 {
  margin-top: 15px;
}
.mrgnbtm15 {
  margin-bottom: 15px;
}
.mrgnright20 {
  margin-right: 20px;
}
.mrgnleft20 {
  margin-left: 20px;
}
.mrgntop20 {
  margin-top: 20px;
}
.mrgnbtm20 {
  margin-bottom: 20px;
}
.mrgnright25 {
  margin-right: 25px;
}
.mrgnleft25 {
  margin-left: 25px;
}
.mrgntop25 {
  margin-top: 25px;
}
.mrgnbtm25 {
  margin-bottom: 25px;
}
.mrgnright30 {
  margin-right: 30px;
}
.mrgnleft30 {
  margin-left: 30px;
}
.mrgntop30 {
  margin-top: 30px;
}
.mrgnbtm30 {
  margin-bottom: 30px;
}
.mrgnright40 {
  margin-right: 40px;
}
.mrgnleft40 {
  margin-left: 40px;
}
.mrgntop40 {
  margin-top: 40px;
}
.mrgnbtm40 {
  margin-bottom: 40px;
}
.mrgnright45 {
  margin-right: 45px;
}
.mrgnleft45 {
  margin-left: 45px;
}
.mrgntop45 {
  margin-top: 45px;
}
.mrgnbtm45 {
  margin-bottom: 45px;
}
.mrgnright50 {
  margin-right: 50px;
}
.mrgnleft50 {
  margin-left: 50px;
}
.mrgntop50 {
  margin-top: 50px;
}
.mrgnbtm50 {
  margin-bottom: 50px;
}
.mrgnright60 {
  margin-right: 60px;
}
.mrgnleft60 {
  margin-left: 60px;
}
.mrgntop60 {
  margin-top: 60px;
}
.mrgntop70 {
  margin-top: 70px;
}
.mrgnbtm60 {
  margin-bottom: 60px;
}
.videoWrapper {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
  }
  .videoEle {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
  }
}
.videoPortraitWrapper {
  .videoEle {
    width: 100%;
    height: 498px;
  }
}
.parallax {
  background: url("https://images.unsplash.com/photo-1501785888041-af3ef285b470?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80")
    no-repeat center center;
  position: relative;
  margin: 0 auto;
  width: 100%;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-box-shadow: inset 0 0 23px 0 rgba(0, 0, 0, 0.75);
  -moz-box-shadow: inset 0 0 23px 0 rgba(0, 0, 0, 0.75);
  box-shadow: inset 0 0 23px 0 rgba(0, 0, 0, 0.75);
}
.pointerEventNone {
  pointer-events: none;
}
.accordion {
  dt {
    cursor: pointer;
    border: 1px solid #dddddd;
    display: block;
  }
  dd {
    border: 1px solid #dddddd;
    display: block;
    border-top: 0;
  }
}
.tour-cancel-button {
  background: linear-gradient(
    275.76deg,
    #1a2a21 44.33%,
    #7bed9f 98.56%
  ) !important;
  color: $color_7 !important;
  border-radius: 50px !important;
}
.tour-next-button {
  background: linear-gradient(
    275.76deg,
    #2ed573 44.33%,
    #7bed9f 98.56%
  ) !important;
  color: $color_7 !important;
  border-radius: 50px !important;
}
.tour-back-button {
  border-radius: 50px !important;
}
.tour-location-step_8 {
  background: #373d3f !important;
  color: $color_7 !important;
}
.shepherd-footer {
  justify-content: space-between !important;
}
.shepherd-text {
  text-align: center !important;
  padding-bottom: 2em !important;
}
@media only screen and (max-width: 768px) {
  .flexResponsive {
    display: block !important;
  }
  .flexResponsiveGrid {
    display: grid !important;
    justify-content: initial;
  }
  .flexReverse {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex: initial !important;
  }
  .mobiVisible {
    display: inline-block !important;
  }
  .desktopVisible {
    display: none !important;
  }
  .width95 {
    width: 100% !important;
  }
  .width90 {
    width: 100% !important;
  }
  .width85 {
    width: 100% !important;
  }
  .width80 {
    width: 100% !important;
  }
  .width75 {
    width: 100% !important;
  }
  .width70 {
    width: 100% !important;
  }
  .width67 {
    width: 100% !important;
  }
  .width66 {
    width: 100% !important;
  }
  .width65 {
    width: 100% !important;
  }
  .width62 {
    width: 100% !important;
  }
  .width60 {
    width: 100% !important;
  }
  .width50 {
    width: 100% !important;
  }
  .width55 {
    width: 100% !important;
  }
  .width40 {
    width: 100% !important;
  }
  .width45 {
    width: 100% !important;
  }
  .width35 {
    width: 100% !important;
  }
  .width34 {
    width: 100% !important;
  }
  .width33 {
    width: 100% !important;
  }
  .width32 {
    width: 100% !important;
  }
  .width30 {
    width: 100% !important;
  }
  .width25 {
    width: 100% !important;
  }
  .width20 {
    width: 100% !important;
  }
  .width15 {
    width: 100% !important;
  }
  .width10 {
    width: 100% !important;
  }
  .width5 {
    width: 100% !important;
  }
  .responsiveBreakText {
    display: inline-block;
  }
  .responsiveBreakTextRev {
    display: block;
  }
  .txtRightAlignResponsive {
    text-align: center !important;
  }
  .txtAlignResponsive {
    text-align: center !important;
  }
}
