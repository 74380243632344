$color_1: #ffffff;
$color_2: "blue";
$color_3: "#1245A8";
$color_4: black;
$color_5: #858489;
$color_6: #1c5bff;
$color_7: rgb(148 163 184);
$color_8: rgb(71 85 105);
$color_9: #fff;
$color_10: #2e2e2e;
$background-color_1: rgb(13, 118, 223);
$background-color_2: #ffffff;
$background-color_3: #006297;
$background-color_4: white;
$background-color_5: rgba(241, 245, 249, 0.5);
$background-color_6: black;
$border-color_1: transparent transparent black transparent;

.viewport {
  background-color: $background-color_1;
}
.node {
  height: auto;
  background-color: $background-color_2;
  // border: 0.5px solid #bfbfbf;
  box-shadow: 0 4px 50px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  border-radius: 8px;
  color: $color_1;
  margin-top: 27px;
  width: 240px;
  & :global {
    .react-flow__handle {
      visibility: hidden;
      margin-top: 30px;
    }
  }
}
.triggerNode {
  color: $color_2;
}
.triggerNodeContent {
  color: $color_3;
}
.nodeHeader {
  padding: 5px 8px;
  font-size: 12px;
  border-bottom: 0.5px solid #bfbfbf;
  display: flex;
  justify-content: space-between;
  color: $color_4;
  background-color: #f2f2f2;
}
.nodeName {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}
.infoIcon {
  display: flex;
  align-items: center;
  padding-top: 5px;
}
.nodeCenter {
  display: flex;
  flex-direction: column;
}
.nodeBody {
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: flex-start;
  padding: 10px;
  gap: 5px;
  isolation: isolate;
  height: max-content;
}
.allDepartment {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: $color_5;
  padding-bottom: 10px;
}
.cardButton {
  cursor: pointer;
  border-radius: 50px;
  font-size: 12px;
  color: #1c5bff;
  margin: 3px 3px 3px 0;
  background-color: white;
  border: 1px solid blue;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px 10px;
  gap: 5px;
  .material-symbols-rounded {
    font-size: 15px !important;
  }
}
.nodeTwd {
  width: 19px;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  margin: 0;
}
.deptClass {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
.actionIcons {
  cursor: pointer;
  background-color: transparent;
  border-style: none;
  border: none;
  color: #858489;
}
.userMenuStyle {
  display: flex;
  color: $color_4;
}
.lineTop {
  border-top: 2px solid #bfbfbf;
  color: $color_4;
}
.deptHover {
  cursor: pointer;
}
.label {
  font-size: 12px;
  text-align: center;
}
.button {
  font-size: 9px;
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translate(-50%, 0);
  width: 100px;
  text-align: center;
  color: $color_7;
  background-color: $background-color_5;
  &:hover {
    color: $color_8;
  }
}
.tooltip,
.dashTooltip {
  position: relative;
  display: inline-block;
  .tooltiptext,
  .dashTooltiptext,
  .tooltiptextNodeType {
    visibility: hidden;
    background-color: $background-color_6;
    color: $color_9;
    text-align: center;
    border-radius: 8px;
    position: absolute;
    z-index: 1;
    top: 100%;
    .icons {
      font-size: 15px;
    }
    &::after {
      content: "";
      position: absolute;
      bottom: 100%;
      border-width: 5px;
      border-style: solid;
      border-color: $border-color_1;
    }
  }
  &:hover {
    .tooltiptext,
    .dashTooltiptext,
    .tooltiptextNodeType {
      visibility: visible;
    }
  }
}
.tooltip,
.dashTooltip {
  .tooltiptext,
  .tooltiptextNodeType {
    font-size: 10px;
    width: 120px;
    padding: 5px;
    margin-left: -60px;
    &::after {
      left: 50%;
      margin-left: 30px;
    }
  }
  .dashTooltiptext {
    font-size: 8px;
    width: auto;
    padding: 5px;
    right: -130%;
    &::after {
      left: 5%;
      margin-left: 7px;
    }
  }
  .tooltiptext {
    left: -110%;
  }
  .tooltiptextNodeType {
    left: -88%;
  }
}
.dashTooltip {
  display: flex;
}
.horizontal {
  height: auto;
  width: 100%;
  border-bottom: 1px solid #bfbfbf;
}
.dropDown {
  position: relative;
  margin-bottom: -20px;
  left: 0%;
  display: flex;
  width: 100%;
  justify-content: space-around;
}
.dropdown {
  position: relative;
  border: 1px solid #bfbfbf;
  width: 150px;
  padding: 2px;
  height: 28px;
  margin: 0px;
  padding: 0px;
  font-size: 10px;
  color: $color_5;
  border-radius: 10px;
  border: 1px solid #bfbfbf;
}
.dropdownContainer {
  display: inline-block;
  background-color: $background-color_2;
  height: 35px;
  width: 100px;
  border-radius: 8px;
  border: 1px solid #bfbfbf;
  padding: 5px;
  color: $color_4;
  z-index: 999;
  margin: 3px;
}
.menuContainer {
  margin: 3px;
  border: 1px solid #bfbfbf;
  border-radius: 6px;
}
.dropdownIcon {
  position: absolute;
  right: 4px;
  top: 0px;
}
.option {
  display: flex;
  justify-content: space-between;
  padding: 5px;
  border-bottom: 1px solid #858489;
  font-size: 10px;
}
.nodeStatus {
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  color: $color_10;
  padding: 5px 0 0 0;
}
.flipVertical {
  -moz-transform: scaleY(-1);
  -o-transform: scaleY(-1);
  -webkit-transform: scaleY(-1);
  transform: scaleY(-1);
  filter: FlipV;
  -ms-filter: FlipV;
}
.nbNodeStyle {
  color: #1245a8;
  font-size: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 36px 0 10px 0;
  font-weight: 500;
}
.addToCompare {
  color: $color_5;
  display: flex;
  gap: 5px;
  font-size: 12px;
  span {
    display: flex;
  }
}
.levelIndicator {
  background-color: #1245a8;
  padding: 4px 10px;
  border-radius: 25px;
  font-size: 12px;
  margin-bottom: 4px;
  cursor: pointer;
}
