$color_1: #858489;
$color_2: #2e2e2e;
$color_3: black;
$color_4: #1245a8;
$color_5: #fff;
$font_family: "Poppins", sans-serif;
$background-color_1: rgba(0, 0, 0, 0.5);
$background-color_2: #ffffff;
$background-color_3: #1245a8;

.addNodeForm {
  display: flex;
  flex-direction: column;
  gap: 3px;
  position: relative;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.1);
  &:target {
    &::before {
      content: "";
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $background-color_1;
      z-index: -1;
    }
  }
}
.addNode {
  width: 118px;
  height: 30px;
  display: flex;
  justify-content: space-between;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #2e2e2e;
}
.actionDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.1);
  width: 100%;
  padding: 5px 10px;
  color: #d60e0e;
}
.formGroup {
  display: flex;
  margin-bottom: 10px;
  font-style: normal;
}
.hr {
  height: 0px;
  border: 1px solid #bfbfbf;
}
.hrBottom {
  height: 0px;
  border: 1px solid #bfbfbf;
  margin-top: 10px;
}
.label {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: $color_2;
}
.svg {
  position: relative;
  right: 25px;
  top: 10px;
}
.input[type="text"] {
  font-family: $font_family;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  background-color: $background-color_2;
  height: 30px;
  width: 400px;
  border-radius: 8px;
  border: 1px solid #bfbfbf;
  padding: 7px;
  color: $color_3;
  resize: none;
}
.textarea {
  font-family: $font_family;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  background-color: $background-color_2;
  height: 40px;
  width: 400px;
  border-radius: 8px;
  border: 1px solid #bfbfbf;
  padding: 10px;
  color: $color_3;
  overflow-y: scroll;
  resize: none;
}
.buttonDiv {
  display: flex;
  justify-content: space-between;
  box-shadow: 1px 1px 15px 0 rgba(0, 0, 0, 0.1);
  width: 100%;
  padding: 5px 10px;
  border-radius: 0 0 8px 8px;
}
.buttonDiscard {
  background: #ffffff;
  color: $color_4;
  border: none;
  font-size: 13px;
  font-weight: 600;
  cursor: pointer;
  width: 140px;
  height: 30px;
  border-radius: 8px;
  border: 2px solid #1245a8;
  margin: 8px;
}
.buttonChange {
  background-color: $background-color_3;
  color: $color_5;
  border: none;
  border-radius: 0.25rem;
  font-size: 13px;
  cursor: pointer;
  width: 140px;
  height: 30px;
  background: #1245a8;
  border-radius: 8px;
  margin: 8px;
}
.dropdown {
  width: 400px;
  font-size: 12px;
  color: $color_1;
  border-radius: 6px;
  border: 1px solid #bfbfbf;
}
.dropdownIcon {
  margin-right: 10px;
  position: absolute;
  right: 2px;
  top: 9px;
}
.selectedDpt {
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  font-size: 10px;
  li {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}
.selectedItems {
  display: flex;
  justify-content: space-between;
  border: 1px solid black;
  border-radius: 5px;
  padding: 2px 13px;
  margin-bottom: 2px;
}
.parentPathCards {
  height: auto;
  background-color: #ffffff;
  box-shadow: 0 4px 50px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -2px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 8px;
  label,
  span {
    font-size: 12px;
  }
  span {
    color: #858489;
  }
  label {
    font-weight: 500;
  }
}
.caret {
  color: #1c5bff;
  padding: 0px 3px;
  font-weight: 700;
}
.iconPath {
  font-size: 10px;
  padding: 0px 8px;
}
@media screen and (max-width: 480px) {
  .addNodeForm {
    margin: 20px;
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    padding: 10px;
  }
  .hr {
    width: auto;
    height: 0px;
    border: 1px solid #bfbfbf;
  }
  .label {
    font-size: 12px;
    line-height: 20px;
  }
  .input[type="text"] {
    width: 100%;
    font-family: $font_family;
    font-size: 12px;
    height: 30px;
  }
  .textarea {
    width: 100%;
    font-size: 12px;
    height: 30px;
  }
  .svg {
    right: 15px;
    top: 5px;
  }
  .buttonDiscard {
    width: 100%;
    font-size: 14px;
    height: 35px;
  }
  .buttonChange {
    width: 100%;
    font-size: 14px;
    height: 35px;
  }
  .buttonDiv {
    flex-direction: column;
    align-items: center;
  }
}
