$color_1: #858489;
$color_2: #1245a8;
$color_3: #1dac56;
$color_4: #ff0000;
$background-color_1: #ffffff;

.popupContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  gap: 10px;
  margin: 245px auto;
  position: relative;
  width: 494px;
  height: 222px;
  background: #ffffff;
  border: 2px dashed #bfbfbf;
  border-radius: 4px;
  transform: translate(-50%, -50%);
  z-index: 12;
  visibility: hidden;
  transition: opacity 0.9s ease-in-out, visibility 0.9s ease-in-out;
}
.show {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  margin: 245px auto;
  position: relative;
  width: auto;
  height: 200px;
  background: #ffffff;
  border-radius: 8px;
  opacity: 1;
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.1);
  z-index: 12;
}
.popUpHeader {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.1);
  color: #d60e0e;
  h1 {
    width: 66px;
    height: 30px;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 30px;
    color: #2e2e2e;
  }
}
.button {
  display: flex;
  justify-content: space-between;
  background-color: $background-color_1;
  width: 400px;
  border-radius: 8px;
  border: 1px solid #dddddd;
  padding: 5px;
  align-items: center;
  font-family: "Poppins", sans-serif;
  cursor: pointer;
}
.buttonContainer {
  display: flex;
  flex-direction: left;
  justify-content: space-between;
  align-items: center;
  margin-right: 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  span {
    font-size: 18px;
  }
  p {
    padding-left: 5px;
  }
}
.editColor {
  color: $color_2;
}
.addColor {
  color: $color_3;
}
.deleteColor {
  color: $color_4;
}
.popUpBody {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 5px 10px;
}
.popup {
  z-index: 1000;
  margin: 345px auto;
}
.icons {
  font-size: 10px;
}
@media screen and (max-width: 480px) {
}
