$color_1: #ffffff;
$color_2: #858489;
$color_3: #e0e0e0;
$color_4: #1c5bff;
.parent {
  width: 100%;
}

.bgLessBlueBtnLarge,
.bgLessBlueBtnSmall,
.bgLessRedBtn {
  display: flex;
  gap: 10px;
  cursor: pointer;
  align-items: center;
  background-color: $color_1;
  border-radius: 8px;
  justify-content: center;
  span {
    font-size: 18px;
  }
}
.bgLessBlueBtnSmall,
.bgLessBlueBtnLarge {
  color: $color_4;
  border: 1px solid $color_4;
  margin: auto;
  // margin-top: 10px;
  justify-content: center;
  width: -webkit-fill-available;
  // white-space: nowrap;
  font-weight: 400;
}
.bgLessBlueBtnLarge {
  padding: 18px 5px;
  font-size: 16px;
}

.bgLessRedBtn,
.bgLessBlueBtnSmall {
  padding: 5px 15px;
  font-size: 14px;
  margin-top: 8px;
}
.bgLessRedBtn {
  color: #ff0000;
  border: 1px solid #ff0000;
  font-weight: 500;
}
.addComparePopUp {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 20;
  opacity: 1;
  animation-name: example;
  animation-duration: 0.5s;
  transition: "transform 0.5s ease-out";
}
.addComparePopupContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin: 0 auto;
  position: relative;
  height: 75vh;
  background: #ffffff;
  box-shadow: 1px 1px 6px 0 rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  z-index: 12;
  overflow: auto;
  transition: opacity 0.9s ease-in-out, visibility 0.9s ease-in-out;
  width: 380px;
}
.addComparePopupHeader {
  position: sticky;
  top: 0;
  z-index: 10;
  min-width: 100%;
  background-color: #ffffff;
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    padding: 6px 12px;
    box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid #e0e0e0;
    color: #d60e0e;
    position: fixed;
    width: 100%;
    border-radius: 5px;
    background-color: #ffffff;
    h1 {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 30px;
      color: #2e2e2e;
    }
  }
  .textInput {
    padding: 8px;
    margin-top: 40px;
  }
}
.cardContainer {
  width: 420px;
  margin-top: -10px;
}
.renderListTitle {
  display: flex;
  // gap: 10px;
  align-items: center;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: #2e2e2e;
  padding: 10px;
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.1);
  p {
    color: #2e2e2e;
  }
}
.layerBdr {
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  padding: 4px 8px;
}
.bdrCompareTable {
  border: 0.5px solid $color_3;
}
.compareTable {
  width: 100%;
  background-color: #ffffff;
  border: 0.5px solid $color_3;
  // min-height: 100vh;
  .compareRow {
    display: flex;
    align-items: stretch;
    // border: 0.5px solid $color_3;
    .compareCell {
      flex-shrink: 0;
      width: 23%;
      // border-left: 0.5px solid $color_3;
      // border-right: 1px solid $color_3;
      border: 1px solid $color_3;
      padding: 28px 24px;
      background-color: $color_1;
      font-size: 14px;
      font-weight: 400;
      color: #2e2e2e;
      overflow-wrap: break-word;
      a {
        display: flex;
        color: $color_4;
      }
    }
    .compareHeadingCell {
      color: #858489;
      width: 18% !important;
      background-color: #f9f3ff;
    }
    .compareColumnFixed {
      position: sticky;
      left: 0;
    }
  }
}
.clipBoard {
  display: flex;
  color: $color_4;
  cursor: pointer;
}
@media screen and (max-width: 480px) {
  .compareTable {
    .compareRow {
      .compareCell {
        padding: 18px 12px;
        width: 100% !important;
        max-width: 50%;
        overflow-wrap: break-word;
        // display: contents  ;
        // overflow-wrap: anywhere;
      }
      .compareHeadingCell {
        max-width: 30%;
      }
    }
  }
  .bgLessRedBtn {
    padding: 2px 28px;
    font-size: 8px;
    gap: 2px;
  }
  .bgLessBlueBtnLarge {
    padding: 8px 26px;
    font-size: 8px;
    gap: 0px;
  }
  .addComparePopUp,
  .addComparePopupContainer {
    height: 100vh;
    width: 100%;
    min-width: auto;
  }

  .cardContainer {
    width: 100%;
  }
}
